<template>
  <div>
    <Header />
    <div class="faq">
      <!-- faq search section -->
      <section id="faq-search-filter">
        <div class="faq-top breadcrumbs d-flex align-items-center">
          <div
            class="container position-relative d-flex flex-column align-items-center"
          >
            <!-- <h2 class="">{{ $t("faq.Lets_answer") }}</h2> -->

            <!-- form -->
            <!-- <b-form class="faq-search-input">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="searchbar"
                  v-model="faqSearchQuery"
                  :placeholder="$t('faq.Search_faq')"
                />
              </b-input-group>
            </b-form> -->
            <!-- form -->
          </div>
        </div>
      </section>
      <!--/ faq search section -->
      <b-container>
        <b-row>
          <b-col sm="12" md="12">
            <div class="banner-box1">
              <div class="container text-center">
                <h1 class="faq-title h1">{{ $t("faq.faq") }}</h1>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- frequently asked questions tabs pills -->
        <section id="faq-tabs" class="mb-3">
          <b-tabs
            vertical
            content-class="col-12 col-md-8 col-lg-9"
            pills
            nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
            nav-class="nav-left"
          >
            <!-- FAQ ALL tab -->
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon icon="HelpCircleIcon" size="18" class="mr-1" />
                <span class="font-weight-bold">{{ $t("faq.faq") }}</span>
              </template>

              <faq-question-answer :options="faqGeneral" />
            </b-tab>
            <!--/ FAQ ALL tab -->

            <!-- sitting lady image -->

            <!--/ sitting lady image -->
          </b-tabs>
        </section>
        <b-row class="mb-5">
          <b-col sm="12" md="12">
            <div class="banner-box1">
              <div class="container">
                <p>{{ $t("faq.faq_footer") }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
        <!--/ frequently asked questions tabs pills -->
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/layouts/landpage/HeaderHome.vue";

import Footer from "@/layouts/landpage/Footer.vue";
import {
  BContainer,
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
} from "bootstrap-vue";
import FaqQuestionAnswer from "./FaqQuestionAnswer.vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    Header,
    Footer,
    BContainer,
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
    ToastificationContent,
  },
  data() {
    return {
      faqSearchQuery: "",
      faqData: [],
      faqGeneral: [],
      faqCompanies: [],
      faqFreelancers: [],
      faqInvestors: [],
      faqBrokers: [],
      faqAdvisors: [],
      faqPartners: [],
      faqVerification: [],
      faqBusinessLoans: [],
      faqIdeas: [],
      faqProjects: [],
      faqServices: [],
      faqProducts: [],
      icons: {
        faqCompanies: "ServerIcon",
        faqFreelancers: "StarIcon",
        faqInvestors: "UsersIcon",
        faqBrokers: "TargetIcon",
        faqAdvisors: "KeyIcon",
        faqPartners: "ShieldIcon",
        faqVerification: "CheckCircleIcon",
        faqBusinessLoans: "DollarSignIcon",
        faqIdeas: "TwitchIcon",
        faqProjects: "MessageCircleIcon",
        faqServices: "TrelloIcon",
        faqProducts: "ShoppingBagIcon",
      },
    };
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    fetchData() {
      const formData = new FormData();
      formData.append("q", this.faqSearchQuery);
      // formData.append("page", currentPage.value);
      formData.append("perPage", 12);
      formData.append("type", "faq");
      formData.append("language", localStorage.getItem("language"));
      formData.append("belongFor", "general");

      axios.post("posts/search/", formData).then((res) => {
        const data = [
          {
            title: "What is Arab Investment Club?",
            content:
              "Arab Investment Club is an investment disposing platform that connects investors and individuals looking to sell their investments. It provides a secure and efficient marketplace for buying and selling various investment assets.",
          },
          {
            title: "How does Arab Investment Club work?",
            content:
              "To use Arab Investment Club, sellers list their investments on the platform, specifying details such as type, quantity, and desired price. Buyers can browse the available investments, conduct due diligence, and submit purchase offers. Once a buyer and seller agree on a transaction, Arab Investment Club facilitates the secure transfer of funds and ownership.",
          },
          {
            title:
              "What types of investments can I find on Arab Investment Club?",
            content:
              "Arab Investment Club offers a wide range of investment assets, including stocks, bonds, mutual funds, ETFs (Exchange-Traded Funds), real estate investment trusts (REITs), and more. The availability of specific investments may vary over time.",
          },
          {
            title: "Is Arab Investment Club secure?",
            content:
              "Yes, Arab Investment Club prioritizes the security and privacy of its users. We employ robust security measures to protect your personal and financial information. Additionally, we use secure payment gateways and encryption protocols to ensure safe transactions.",
          },
          {
            title: "How can I create an account on Arab Investment Club?",
            content:
              "To create an account, visit our website and click on the Sign Up or Register button. Follow the instructions to provide the required information, such as your name, email address, and password. You may need to verify your email to activate your account.",
          },
          {
            title: "Are there any fees for using Arab Investment Club?",
            content:
              "Arab Investment Club may charge transaction fees or commissions on successful investment sales. The exact fees and commission structure will be communicated to you during the listing process or in our terms and conditions.",
          },
          {
            title:
              "Can I sell investments from different countries on Arab Investment Club?",
            content:
              "Arab Investment Club aims to facilitate investment transactions primarily within Arab countries. However, the availability of specific investment options may depend on regulatory requirements and market conditions. Please refer to the platform for the most up-to-date information.",
          },
          {
            title:
              "What happens if there is a dispute between a buyer and seller?",
            content:
              "Arab Investment Club encourages open communication and transparency between buyers and sellers. In case of a dispute, we recommend reaching out to our customer support team, who will provide guidance and facilitate a resolution process. We may require relevant documentation and evidence to investigate and resolve the dispute fairly.",
          },
          {
            title: "Is my personal information kept confidential?",
            content:
              "Yes, Arab Investment Club values your privacy and complies with applicable data protection laws. We have a Privacy Policy in place that outlines how we collect, use, and protect your personal information. Please refer to our Privacy Policy for more details.",
          },
          {
            title:
              "How can I contact Arab Investment Club for further assistance?",
            content:
              "If you have any additional questions or need support, you can reach out to our customer support team through the contact information provided on our website. We are available to assist you during our regular business hours.",
          },
        ];
        this.faqGeneral = data;
      });
      // const { data } = response;
      // this.faqData = response.data;

      // totalFaqs.value = data.count;
      // return data.data;

      // } catch (error) {
      //   this.$toast({
      //     component: ToastificationContent,
      //     position: "top-right",
      //     props: {
      //       title:
      //         error.response.status === 401
      //           ? "you dont have permissions to do this action"
      //           : "Error fetching faqs list",
      //       icon: "AlertTriangleIcon",
      //       variant: "danger",
      //     },
      //   });
      // }
    },

    fetchData2() {
      // try {
      const formData = new FormData();
      formData.append("q", this.faqSearchQuery);
      // formData.append("page", currentPage.value);
      // formData.append("perPage", perPage.value);
      formData.append("perPage", 200);

      formData.append("type", "faq");
      formData.append("language", localStorage.getItem("language"));

      axios.post("posts/search/", formData).then((res) => {
        this.faqData = res.data.data;

        this.faqData.forEach((obj, index) => {
          if (obj.belongFor == "companies") {
            this.faqCompanies.push(obj);
          } else if (obj.belongFor == "freelancers") {
            this.faqFreelancers.push(obj);
          } else if (obj.belongFor == "investors") {
            this.faqInvestors.push(obj);
          } else if (obj.belongFor == "brokers") {
            this.faqBrokers.push(obj);
          } else if (obj.belongFor == "partners") {
          } else if (obj.belongFor == "advisors") {
            this.faqAdvisors.push(obj);
          } else if (obj.belongFor == "partners") {
            this.faqPartners.push(obj);
          } else if (obj.belongFor == "verification") {
            this.faqVerification.push(obj);
          } else if (obj.belongFor == "businessLoans") {
            this.faqBusinessLoans.push(obj);
          } else if (obj.belongFor == "ideas") {
            this.faqIdeas.push(obj);
          } else if (obj.belongFor == "projects") {
            this.faqprojects.push(obj);
          } else if (obj.belongFor == "services") {
            this.faqServices.push(obj);
          } else if (obj.belongFor == "products") {
            this.faqProducts.push(obj);
          }
        });
      });

      // } catch (error) {
      // }
    },
  },
  mounted() {
    this.fetchData2();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
<style>
.faq-top {
  margin-bottom: 75px;
}
/* .card.faq-search {
  background-repeat: no-repeat;
  background-position: center;
} */

.faq-top {
  background-image: url(../../../assets/images/info/faq.png);
  padding: 140px 0 60px 0;
  min-height: 60vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.banner-box1 h1 {
  font-size: 40px;
  color: #2f2d7e;
}
.faq-title {
  font-size: 40px;
  font-weight: 700;
  color: #366ce3;
  margin-bottom: 30px;
}
.breadcrumbs:before {
  content: "";
  background-color: rgba(27, 47, 69, 0.7);
  position: absolute;
  inset: 0;
}
.breadcrumbs h2 {
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}
.faq-search-input {
  width: 30%;
}

@media (max-width: 720px) {
  .faq-search-input {
    width: 60%;
  }
  .breadcrumbs h2 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    font-family: var(--font-secondary);
  }
}
</style>
